@import '../../../assets/style/varibales';

.accordion {
    max-width: 768px;

    @media (max-width: $mobile) {
        max-width: 100%;
    }
}

.head {
    width: 672px;
    height: 72px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    text-decoration: none;

    @media (max-width: $mobile) {
        width: 100%;
    }
}

.title {
    font-family: MegaFon Graphik LC-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #333333;
    margin-left: 25px;

    @media (max-width: $mobile) {
        margin-left: 15px;
    }
}

.image {
    margin-right: 19px;
}

.open {
    border-top: 1px solid #D8D8D8;
    border-bottom: none;
    background: #EDEDED;
}

.close {
    border-bottom: 1px solid #D8D8D8;
}

.item {
    width: 580px;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    border-bottom: 1px solid #D8D8D8;
    margin-left: 27px;

    @media (max-width: $mobile) {
        max-width: 91%;
    }
}

.link {
    text-decoration: none;
    font-family: MegaFon Graphik LC-Light;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #333333;
}




