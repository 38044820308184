@import '../../assets/style/varibales';

.request {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $desktop) {
    padding: 0;
  }

  @media (max-width: $tablet) {
    padding: 0;
  }
}

.title {
  font-family: LatoWebBold;
  font-size: 36px;
  color: $black;
}
