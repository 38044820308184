@import '../../assets/style/varibales';

.scopeWrapper {
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 99%;
  padding-left: 9px;

  @media (max-width: $tablet) {
    padding-bottom: 8px;
    padding-left: 22px;
    width: 97%;
  }

  @media (max-width: $mobile) {
    padding-left: 0;
  }
}

.scope {
  width: 100%;
  background: #ffffff;
  padding-top: 29px;

  @media (max-width: $desktop) {
    padding-top: 25px;
    margin-bottom: 25px;
  }

  @media (max-width: $tablet) {
    max-width: 708px;
  }

  @media (max-width: $mobile) {
    padding: 28px 0 8px 0;
    margin-bottom: 0;
    max-width: 100%;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: $tablet) {
    padding-left: 8px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
  }
}

.divider {
  display: none;
  order: 1;
  height: 1px;
  width: 100%;
  margin: 30px;
  background: rgba(#000000, 0.1);

  @media (max-width: $tablet) {
    display: block;
  }

  @media (max-width: $mobile) {
    margin: 20px;
  }
}
