@import '../../../assets/style/varibales';

.head {
  display: flex;
  justify-content: center;
  border-bottom: 1px $silver solid;
  box-shadow: 0px 0.25px 2px rgba(0, 0, 0, 0.36);
  background: #FFFFFF;

  @media (max-width: $tablet) {
    align-items: center;
    height: 64px;
  }

  @media (max-width: $mobile) {
    padding-left: 14px;
    padding-right: 14px;
  }
}

.headWrapper {
  display: flex;
  width: 1165px;
  justify-content: space-between;
  margin: auto;

  @media (max-width: $desktop) {
    width: 924px;
  }  

  @media (max-width: $tablet) {
    width: 703px;
  }

  @media (max-width: $mobile) {
    width: 318px;
  }
}

.splitter {
  width: 714px;

  @media (max-width: $desktop) {
    width: 490px;
  }

  @media (max-width: $tablet) {
    width: 275px;
  }

  @media (max-width: $mobile) {
    display: none;
  }
}

.logoContainer {
  width: 269px;
  height: 52px;
  padding-top: 6px;
  margin-left: 22px;

  @media (max-width: $desktop) {
    margin-left: 14px;
  }

  @media (max-width: $tablet) {
    margin-left: 26px;
    padding-top: 6px;
  }

  @media (max-width: $mobile) {
    margin-left: 0;
    margin-right: 0;
    width: 219px;
  }
}

.logo {
  height: 52px;
  
  @media (max-width: $tablet) {
    width: 245px;
  }

  @media (max-width: $mobile) {
    width: 214px;
  }
}

.container {
  display: flex;
  margin: 16px 40px 16px 0;
  
  @media (max-width: $desktop) {
    margin-right: 12px;
  }

  @media (max-width: $tablet) {
    margin-right: 0;
  }

  @media (max-width: $mobile) {
    width: 22%;
  }
}

.button {
  width: 70px;
  height: 32px;
  background: #00B956;
  border-radius: 100px;
  border: none;
  font-family: MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  align-self: center;
  padding: 0 20px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;


  @media (max-width: $mobile) {
    width: 60px;
    height: 32px;
    background: #FFFFFF;
    border: none;
    align-self: center;
    padding: 0 0;
    cursor: pointer;

  }
}

.image {
  display: none;

  @media (max-width: $mobile) {
    display: block;
    width: 74px;
    height: 32px;
  }
}

.description {
  margin: 0 60px 0 0;
  font-family: MegaFon Graphik LC-Light;
  font-size: 13px;
  color: $silverDark;

  @media (max-width: $mobile) {
    display: none;
  }
}

.link {
  width: 109px;
  height: 32px;
  vertical-align: middle;
  font-family: MegaFon Graphik LC;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  border-radius: 100px;
  background-color: #00B956;
  text-decoration: none;

  @media (max-width: $mobile) {
    padding: 6px 12px;
  }
}
