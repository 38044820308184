@import '../../assets/style/varibales';

.documents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 96px;
  padding-top: 3px;

  @media (max-width: $tablet) {
    padding: 0 0 72px;
    margin-bottom: 0;
  }

  @media (max-width: $mobile) {
    padding: 19px 0 24px;
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 38px;
  }
}

.title {
  font-family:  MegaFon Graphik LC-Medium;
  font-size: 32px;
  color: $black;
  margin-bottom: 58px;
  letter-spacing: 0.5px;

  @media (max-width: $desktop) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 54px;
  }

  @media (max-width: $tablet) {
    font-size: 26px;
    line-height: 32px;
    padding-top: 3px;
  }

  @media (max-width: $mobile) {
    font-size: 22px;
    margin-bottom: 38px;
  }
}

.container {
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  width: 740px;
  border-bottom: 1px solid #D8D8D8;;
  margin-left: 10px;
  letter-spacing: 0.2px;

  @media (max-width: $tablet) {
    margin-left: 30px;
    width: 90%;
  }

  @media (max-width: $mobile) {
    max-width: 279px;
    padding-bottom: 14px;
    margin-left: 0;
    display: flex;
    align-items: flex-start;
    border-bottom: none;
  }
}

.text {
  margin-left: 12px;
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #34AAF2;
  text-decoration: none;
  white-space: pre-wrap;
}