@import '../../../assets/style/varibales';

.label {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: MegaFon Graphik LC-Medium;
  font-size: 14px;
  color: $silverDark;
  height: 100%;

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cross {
  &::before {
    position: absolute;
    right: 26px;
    top: 41px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: red;
    transform: rotate(45deg);
    z-index: 1;
  }

  &::after {
    position: absolute;
    right: 26px;
    top: 41px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: red;
    transform: rotate(-45deg);
    z-index: 1;
  }
}

.labelText {
  display: flex;
  width: 100%;
  justify-content: left;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
}

.input {
  box-sizing: border-box;
  max-width: 550px;
  width: 100%;
  height: 48px;
  padding: 0 0 0 12px;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  font-family: MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  outline: none;
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: .9s;

  &::placeholder {
    color: #97A1A8;
    font-size: 15px;
    opacity: .5;
  }

  @media (max-width: $mobile) {
    margin: 2px 0 0;
    max-width: 99%;
  }

}


@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.notFilled {
  border: 1px solid red;
}

.warning {
  font-family: MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #F62434;
  margin-top: 4px;
  width: 100%;
}
