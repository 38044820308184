@import '../../../assets/style/varibales';

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 284px;

  &:nth-child(4) {
    border-right: none;
  }

  @media (max-width: $desktop) {
    width: 229px;

    &:first-child {
      .textWrapper {
        margin-bottom: 28px;
      }
    }
  }

  @media (max-width: $tablet) {
    align-items: normal;
    flex-basis: calc(50% - 1px);

    &:nth-child(1) {
      .textWrapper {
        margin-bottom: 0px;
        padding: 0 65px 0 0;
      }
    }
    &:nth-child(2) {
      .textWrapper {
        margin-bottom: 0px;
        border-right: none;
      }
    }
  }

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    border-right: none;
    width: 100%;
    align-items: center;

    &:first-child {
      .textWrapper {
        padding: 0;
      }
    }
  }
}

.image {
  width: 100%;
  padding: 0 20px 0 32px;
  align-items: center;
  display: flex;
  margin: 27px 0 32px 0;

  img {
    width: 150px;
  }

  @media (max-width: $tablet) {
    padding-left: 0;
  }

  @media (max-width: $mobile) {
    padding: 0 20px 0 22px;
    justify-content: center;
    margin-bottom: 26px;
    width: 86%;
  }
}

.textWrapper {
  padding: 0 25px 0 0;
  max-width: 265px;
  min-height: 72px;
  display: flex;
  margin-bottom: 56px; 

  @media (max-width: $tablet) {
    margin-bottom: 0;
    padding: 0;
    max-width: 281px;
  }

  @media (max-width: $mobile) {
    max-width: 300px;
    width: 300px;
    padding-right: 0;
  }
}

.text {
  font-family: MegaFon Graphik LC-Light;
  font-size: 15px;
  color: #000000;
  text-align: left;
  line-height: 24px;
  display: flex;
  letter-spacing: 0.2px;
  white-space: pre-wrap;

  @media (max-width: $mobile) {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    width: 300px;
    display: flow-root;
    padding-left: 7px;
  }
}
