$silverLighten: rgba(#edf2f5, 0.4);
$silverLight: #edf2f5;
$silverDark: #6b7378;
$silver: #e3eaee;

$blue: #0080cc;

$black: #131e24;

$red: #d4003b;

$desktop: 1279px;
$tablet: 1023px;
$mobile: 767px;
