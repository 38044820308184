.wrapper {
  opacity: 0;
}

.animateIn {
  animation: animateIn 1s ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
