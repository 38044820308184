@import '../../../assets/style/varibales';

.requestForm {
  margin-bottom: 96px;
  max-width: 720px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: .9s;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $desktop) {
    margin-bottom: 66px;
  }

  @media (max-width: $mobile) {
    padding: 0 26px 11px;
    margin-bottom: 0;
  }
}

.requestResult {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: .9s;
  margin-top: 30px;
}

.buttonBack {
  margin: 30px 0 0 !important;
  width: 240px !important;
}

.requestResultText {
  font-family: MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  width: 70%;
}

.title {
  font-size: 32px;
  font-weight: 800;
  line-height: 1.25;
  font-family:  MegaFon Graphik LC-Medium;
  min-width: 277px;
  text-align: center;

  @media (max-width: $tablet) {
    font-size: 28px;
  }

  @media (max-width: $mobile) {
    font-size: 26px;
  }
}

.margin {
  margin: 28px 0 0;

  @media (max-width: $mobile) {
    margin-top: 27px;
  }
}

.form {
  max-width: 550px;
  
  @media (max-width: $desktop) {
    width: 440px;
  }

  @media (max-width: $mobile) {
    padding-top: 6px;
    width: 100%;
  }
}

.inputContainer {
  display: flex;
  margin-top: 28px;

  @media (max-width: $mobile) {
    margin-top: 15px;
    flex-direction: column;
  }
}

.input {
  width: 100%;
  font-family: MegaFon Graphik LC-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  height: 100%;
  input {
    height: 48px !important;
  }
}

.inputShort {
  width: 265px;
  max-width: 265px;
  font-family: MegaFon Graphik LC-Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;

  &:nth-child(1) {
    margin-right: 20px;
  }

  @media (max-width: $mobile) {
    width: 100%;
    max-width: 100%;

    &:nth-child(1) {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-right: 0;
      margin-top: 27px;
    }
  }
}

.phoneInput {
  width: 100%;
  max-width: 550px;
  height: 48px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  padding: 0 0 0 12px;
  font-family: MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  outline: none;
  box-sizing: border-box;

  @media (max-width: $mobile) {
    max-width: 99%;
    margin: 2px 0 0;
  }
}

.warningInput {
  border: 1px solid red;
}

.textarea {
  height: 120px;
  max-width: 99%;
  box-sizing: border-box;
  padding: 12px 0 0 12px;
  border-radius: 4px;
  border: 1px solid #D8D8D8;
  font-family: MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  outline: none;

  &::placeholder {
    color: #97A1A8;
    font-size: 15px;
    opacity: .5;
  }

  @media (max-width: $mobile) {
    margin: 2px 0 0;
    height: 98px;
  }
}

.button {
  margin-top: 14px;
  width: 143px;
  background: #00B956;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  font-family:  MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  @media (max-width: $mobile) {
    margin: 43px 0 0;
  }

  input {
    all: unset;
  }
}

.disabledButton {
  background-color: #EDEDED;
  border: 1px solid #D8D8D8;
  color: rgba(51, 51, 51, 0.5);
}

.agreement {
  display: block;
  max-width: 511px;
  margin: 16px 0 0 0;
  font-family:  MegaFon Graphik LC-Light;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $black;

  @media (max-width: $mobile) {
    margin-top: 27px;
    text-align: center;
  }
}

.link {
  color: $blue;
  text-decoration: none;
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.dataNotFilled {
  font-family: LatoWeb;
  font-size: 13px;
  color: $red;
  bottom: 165px;
  left: 175px;
}
