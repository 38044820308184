/* Webfont: MegaFon Graphik LC-Black */@font-face {
  font-family: 'MegaFon Graphik LC-Black';
  src: url('fonts/MegaFon\ Graphik\ LC-Black.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MegaFon Graphik LC-Bold */@font-face {
  font-family: 'MegaFon Graphik LC-Bold';
  src: url('fonts/MegaFon\ Graphik\ LC-Bold.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MegaFon Graphik LC-Light */@font-face {
  font-family: 'MegaFon Graphik LC-Light';
  src: url('fonts/MegaFon\ Graphik\ LC-Light.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MegaFon Graphik LC-Medium */@font-face {
  font-family: 'MegaFon Graphik LC-Medium';
  src: url('fonts/MegaFon\ Graphik\ LC-Medium.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MegaFon Graphik LC-Regular */@font-face {
  font-family: 'MegaFon Graphik LC-Regular';
  src: url('fonts/MegaFon\ Graphik\ LC-Regular.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: MegaFon Graphik LC-Semibold */@font-face {
  font-family: 'MegaFon Graphik LC-Semibold';
  src: url('fonts/MegaFon\ Graphik\ LC-Semibold.otf'); /* IE9 Compat Modes */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
