html {
  font-family: Lato;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
