@import '../../assets/style/varibales';

.works {
  justify-content: center;
  overflow: hidden;
  padding: 119px 0 0 3px;
  background: #FFFFFF;

  @media (max-width: $desktop) {
    padding-top: 87px;
  }

  @media (max-width: $tablet) {
    padding: 0 0 35px 0;
    margin-top: 72px;
  }

  @media (max-width: $mobile) {
    margin-top: 53px;
  }
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: $desktop) {
    margin-left: 23px;
  }

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: $mobile) {
    width: 303px;
    margin: auto;
  }
}

.selector {
  top: 200px;
  left: 117px;
  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: $desktop) {
    margin-right: 18px;
  }

  @media (max-width: $tablet) {
    position: relative;
    top: 0;
    left: 0;
    padding: 6px 0 0;
    text-align: center;
    align-items: center;
  }

  @media (max-width: $mobile) {
    padding-top: 0;
    width: 100%;
  }
}

.title {
  font-family:  MegaFon Graphik LC-Medium;
  font-size: 32px;
  line-height: 40px;
  color: $black;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 52px;
  letter-spacing: 0.2px;

  @media (max-width: $desktop) {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 58px;
  }

  @media (max-width: $tablet) {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 0;
    letter-spacing: 0.07px;
  }

  @media (max-width: $mobile) {
    font-size: 23px;
  }
}

.textContainer {
  display: none;

  @media (max-width: $tablet) {
    display: flex;
  }
}

.text {
  display: flex;
  align-items: center;
  width: 500px;
  height: 100%;
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: $black;
  white-space: pre-line;
  margin: 1px 0 0 0;
  letter-spacing: 0.2px;



  @media (max-width: $desktop) {
    width: 408px;
  }

  @media (max-width: $tablet) {
    display: flex;
    justify-content: center;
    margin-bottom: 26px;
    margin-right: 35px;
    margin: 17px 35px 26px 0;
  }

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
    width: 100%;
    margin: 5px 0 10px 0;
    text-align: center;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @media (max-width: $tablet) {
    margin-top: 33px;
  }

  @media (max-width: $mobile) {
    margin-top: 22px;
    margin-left: 16px;
  }
}

.listContainer {
  display: none;

  @media (max-width: $tablet) {
    display: flex;
  }
}

.listItemContainer {
  display: flex;
  align-items: center;
  margin-bottom: 64px;
  cursor: pointer;

  .listItem {

    &::before {
      content: "";
      position: absolute;
      transition: all .5s ease;
      border-radius: 50%;
      width: 0;
      height: 0;
    }
  }

  .index {
    z-index: 2;
  }

  @media (max-width: $desktop) {
    margin-bottom: 59px;
  }

  @media (max-width: $tablet) {
    display: none;
  }

  &:hover {

    .index {
      z-index: 1;
      transition: all .1s ease;
      position: relative;
    }

    .listItem {
      color: #ffffff;
      border: none;

      &::before {
        background-color: #00B956;
        width: 40px;
        height: 40px;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        transition: all .5s ease;
        border-radius: 50%;
        border: 1px solid rgba(51, 51, 51, 0.5);
        width: 38px;
        height: 38px;
        z-index: 0;
      }
    }
  }
}

.listItem {
  display: flex;
  margin: 0 14px 0 0;
  align-items: center;
  justify-content: center;
  font-family: LatoWeb;
  font-size: 16px;
  line-height: 1.81;
  color: #becad1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(51, 51, 51, 0.5);
  box-sizing: border-box;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  @media (max-width: $tablet) {
    margin-right: 39px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      transition: all .5s ease;
      border-radius: 50%;
      width: 0;
      height: 0;
    }

  &:hover {

    .index {
      z-index: 1;
      transition: all .1s ease;
      position: relative;
    }

      color: #ffffff;
      border: none;

      &::before {
        background-color: #00B956;
        width: 40px;
        height: 40px;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        transition: all .5s ease;
        border-radius: 50%;
        border: 1px solid rgba(51, 51, 51, 0.5);
        width: 38px;
        height: 38px;
        z-index: 0;
      }
    }
  }

  @media (max-width: $mobile) {
    margin-right: 20px;

    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.listItemSelected {
  color: #ffffff !important;
  background-color: #00B956;
  border-radius: 50%;
  border: none !important;
}

.disableHover {
  pointer-events: none;
}

.slider {
  width: 43%;
  max-width: 554px;
  max-height: 330px;
  margin: 46px 15px 0 0;

  @media (max-width: $desktop) {
    margin-top: 117px;
  }

  @media (max-width: $tablet) {
    width: 100%;
    margin: 47px 31px 1px 0;
  }

  @media (max-width: $mobile) {
    margin: 32px 16px 16px 0;
  }
}
