@import '../../assets/style/varibales';

.advantages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 93px 0 98px 55px;
  background: #F6F6F6;

  @media (max-width: $desktop) {
    padding: 73px 0 98px 66px;
  }

  @media (max-width: $tablet) {
    padding: 72px 0 70px;
  }

  @media (max-width: $mobile) {
    padding: 59px 20px 80px;
  }
}

.title {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  font-family:  MegaFon Graphik LC-Medium;
  color: $black;
  letter-spacing: 1px;
  padding-right: 52px;

  @media (max-width: $desktop) {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
  }

  @media (max-width: $tablet) {
    font-size: 26px;
    line-height: 32px;
    margin-left: 55px;
  } 

  @media (max-width: $mobile) {
    font-size: 26px;
    letter-spacing: 2px;
    max-width: 75%;
  }
}

.list {
  max-width: 1140px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-right: 35px;

  @media (max-width: $desktop) {
  }

  @media (max-width: $tablet) {
    max-width: 708px;
    margin: 0 0 0 33px;
  }

  @media (max-width: $mobile) {
    margin: 0 0;
    display: none;
  }
}

.listItem {
  box-sizing: border-box;
  padding: 39px 0 0 0;
  flex-basis: calc(100% / 3);
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (max-width: $desktop) {
    flex-basis: auto;
    flex-direction: row;
    width: 455px;

    &:nth-child(4) {
      .itemTitle {
        width: 240px;
      }
    }
  }

  @media (max-width: $tablet) {
    flex-basis: 50%;
    flex-direction: column;
  }

  @media (max-width: $mobile) {
    padding: 33px 0 0;
    text-align: center;
    flex-basis: 100%;
    align-items: center;
    margin-bottom: 7px;
    width: 100%;
  }
}

.visibleForDesktop {

  @media (max-width: $mobile) {
    display: none;
  }
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: 19px;

  @media (max-width: $tablet) {
    margin-bottom: 15px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 0;
    margin-left: 16px;
  }

}

.textBlock {
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.itemTitle {
  margin: 16px 0 0;
  max-width: 340px;
  font-family:  MegaFon Graphik LC-Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: $black;
  white-space: pre-wrap;

  @media (max-width: $desktop) {
    margin-top: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #000000;
  }

  @media (max-width: $tablet) {
    max-width: 231px;
  }

  @media (max-width: $mobile) {
    margin: 16px 0 0;
    font-size: 18px;
    max-width: 70%;
  }
}

.text {
  margin: 5px 0 0;
  max-width: 358px;
  font-family:  MegaFon Graphik LC-Light;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  white-space: pre-wrap;
  letter-spacing: 0.2px;

  @media (max-width: $tablet) {
    max-width: 274px;
  }

  @media (max-width: $mobile) {
    margin: 5px auto 0;
  }
}

.slide {
  cursor: pointer;
  width: 100%;
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }
}

.pointsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #C5C5C5;
  mix-blend-mode: normal;
  opacity: 0.25;
  margin-right: 7px;
  transition: 0.5s;
}

.activePoint {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #731982;
  margin-right: 7px;
  transition: 0.5s;
}
