@import '../../assets/style/varibales';

.connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 29px;

  @media (max-width: $desktop) {
    padding-top: 4px;
  }

  @media (max-width: $tablet) {
    padding: 4px 0 0;
  }

  @media (max-width: $mobile) {
    padding: 36px 0 0;
  }
}

.title {
  font-family:  MegaFon Graphik LC-Medium;
  font-size: 32px;
  letter-spacing: 0.5px;
  color: $black;

  @media (max-width: $desktop) {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #000000;
    margin-bottom: 67px;
  }

  @media (max-width: $tablet) {
    font-size: 26px;
    line-height: 32px;
    margin: 32px 0 1px 42px;
  }

  @media (max-width: $mobile) {
    font-size: 21px;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 20px;
    letter-spacing: 0.7px;
  }
}

.list {
  max-width: 840px;
  width: 100%;
  display: flex;
  margin: 72px 0 0;
  justify-content: space-between;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: $desktop) {
    flex-wrap: nowrap;
    width: 96%;
    margin-top: 9px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
