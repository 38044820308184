@import '../../../assets/style/varibales';

.main {
  position: relative;
  display: flex;
  background: linear-gradient(180deg, #F6F6F6 20.2%, #ECECF3 100%);
  height: 100%;

  @media (max-width: $tablet) {
    padding: 4px 0 0 2px;
  }
}

.wrapper {
  display: flex;
  margin: auto;
  width: 1165px;

  @media (max-width: $desktop) {
    width: 989px;
  }

  @media (max-width: $tablet) {
    width: 742px;
    align-items: center;
    margin: 0 auto 52px auto;
  }

  @media (max-width: $mobile) {
    margin: 0 auto 13px;
    flex-direction: column;
    max-width: 320px;
    padding-right: 0px;
  }
}

.text {
  padding-top: 118px;
  padding-left: 24px;
  width: 517px;

  @media (max-width: $desktop) {
    padding: 82px 0 0 46px;
    width: 450px;
  }

  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0 0 47px;
    width: 346px;
  }

  @media (max-width: $mobile) {
    padding: 20px 0 0;
    width: 100%;
    align-items: end;
  }
}

.product {
  display: block;
  font-family: MegaFon Graphik LC-Bold;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: 0.5px;
  color: $black;

  @media (max-width: $desktop) {
    font-size: 44px;
    line-height: 48px;
    letter-spacing: 0.5px;
    color: #000000;
  }

  @media (max-width: $tablet) {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;
    width: 349px;
    margin-bottom: 1px;
  }

  @media (max-width: $mobile) {
    font-size: 30px;
    line-height: 36px;
    width: 290px;
  }
}

.description {
  display: block;
  margin: 24px 0 56px 0;
  font-family: MegaFon Graphik LC-Light;
  font-size: 15px;
  line-height: 24px;
  color: $black;
  letter-spacing: 0.2px;

  @media (max-width: $desktop) {
    font-size: 15px;
    line-height: 24px;  
    color: #000000;
  }

  @media (max-width: $tablet) {
    margin-bottom: 47px;
  }

  @media (max-width: $mobile) {
    width: 100%;
    font-size: 15px;
    margin: 8px 5px 0 0;
  }
}

.button {
  padding: 12px 20px;
  width: 185px;
  height: 48px;
  background: #00B956;
  border-radius: 100px;
  border: none;
  font-family: MegaFon Graphik LC-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  align-self: center;
  letter-spacing: 0.2px;
  outline: none;
  cursor: pointer;

  @media (max-width: $tablet) {
    margin-right: 162px;
  }

  @media (max-width: $mobile) {
    margin: 23px 0 0;
    width: 100%;
    padding-left: 32px;
  }
}

.imagesContainer {
  display: flex;
  position: relative;

  @media (max-width: $mobile) {
    width: 228px;
  }
}

.imageMegafon {
  margin: 102px 0 0 11px;
  position: absolute;
  z-index: 0;

  @media (max-width: $desktop) {
    width: 438px;
    height: 138px;
    margin-top: 73px;
  }

  @media (max-width: $tablet) {
    margin-top: 117px;
    margin-left: 0;
    width: 326px;
    height: 103px;
  }
  
  @media (max-width: $mobile) {
    width: 228px;
    height: 72px;
    margin: 0;
    padding-top: 21px;
  }
}

.imageLaptop {
  margin: 92px 0 72px 77px;
  z-index: 1;

  @media (max-width: $desktop) {
    width: 348px;
    height: 281px;
    margin: 66px 0 69px 58px;
  }

  @media (max-width: $tablet) {
    width: 259px;
    height: 209px;
    margin: 111px 0 24px 35px;
  }

  @media (max-width: $mobile) {
    width: 161px;
    height: 131px;
    margin: 0;
    margin-left: 26px;
    margin-top: 12px;
  }
}

.link {
  color: #ffffff;
  text-decoration: none;
}
