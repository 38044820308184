@import '../../../assets/style/varibales';

.card {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-basis: 100%;
  padding: 40px 30px 0 30px;
  width: 200px;
  height: 100%;
  box-sizing: border-box;
  background-color: #ffffff;

  &:nth-child(1) {
    background: linear-gradient(to right, #FFFFFF 50%, #B3EACC 50%) top;
    background-repeat: no-repeat;
    background-size:100% 2px;
  }  

  &:nth-child(2) {
    background: linear-gradient(to right, #B3EACC 100%,) top;
    background-repeat: no-repeat;
    background-size:100% 2px;
  }

  &:nth-child(3) {
    background: linear-gradient(to right, #B3EACC 50%,  #FFFFFF 50%) top;
    background-repeat: no-repeat;
    background-size:100% 2px;
  }  

  @media (max-width: $tablet) {
    flex-basis: 59%;
    height: 163px;
    margin: 67px 0 0;
    padding: 59px 40px;
  }

  @media (max-width: $mobile) {
    flex-basis: 84%;
    height: 101px;
    padding: 35px 15px 17px 15px;
    margin-top: 0;
    
    &:nth-child(1) {
      background: none;
      background-repeat: no-repeat;
      background-size: 0;
    }  
  
    &:nth-child(2) {
      background: none;
      background-repeat: no-repeat;
      background-size: 0;
    }
  
    &:nth-child(3) {
      background: none;
      background-repeat: no-repeat;
      background-size: 0;
    }  
  }
}

.index {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #E6F8EE;
  font-family:  MegaFon Graphik LC-Medium;
  font-size: 15px;
  line-height: 24px;
  color: #00B956;

  @media (max-width: $mobile) {
    width: 32px;
    height: 32px;
    transform: none;
  }
}

.title {
  font-family:  MegaFon Graphik LC-Medium;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #333333;
  width: 200px;
  margin-bottom: 12px;

  @media (max-width: $tablet) {
    transform: translateY(-19px);
  }

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 1.33;
    transform: none;
    margin-top: 7px;
    margin-bottom: 6px;
    letter-spacing: -0.5px;
  }
}

.text {
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  vertical-align: top;
  color: $silverDark;
  width: 200px;
  height: 64px;
  white-space: pre-wrap;

  @media (max-width: $tablet) {
    transform: translateY(-20px);
  }

  @media (max-width: $mobile) {
    transform: none;
    width: 285px;
    letter-spacing: 0.2px;
  }
}
