@import '../../../assets/style/varibales';

.button {
  padding: 12px 38px;
  border-radius: 4px;
  border: 1px solid transparent;
  // box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.22), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.19);
  background-color: $blue;
  outline: none;
  font-family: LatoWeb;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;

  @media (max-width: 414px) {
    font-size: 18px;
    padding: 8px 38px;
  }
}
