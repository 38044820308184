@import '../../assets/style/varibales';

.footer {
  padding-top: 40px;
  border-top: 1px solid #97a1a75c;
  background-color: #F6F6F6;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: $desktop) {
    padding-top: 16px;
  }

  @media (max-width: $tablet) {
    padding-top: 0;
  }

  @media (max-width: $mobile) {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}

.containerTop {
  display: flex;
  max-width: 1153px;
  margin: 0 auto 24px;
  padding-bottom: 19px;

  border-bottom: 1px solid #D9D9D9;

  @media (max-width: $desktop) {
    margin-left: 47px;
    margin: 0;
  }

  @media (max-width: $tablet) {
    max-width: 768px;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'logo contacts companyInfo'
      'logo documents companyInfo';
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 20px;
    margin: auto;
  }

  @media (max-width: $mobile) {
    margin-left: 1px;
    margin-bottom: 0;
    width: 99.7%;
  }
}

.accordionContainer {
  width: 100%;
  display: none;

  @media (max-width: $tablet) {
    display: block;
  }
}

.desktopContainer {
  display: flex;

  @media (max-width: $tablet) {
    display: none;
  }
}

.title {
  font-family: MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  letter-spacing: 0.2px;
}

.itemContainer {
  margin: 10px 0 12px 0;
}

.listItem {
  font-family: MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  mix-blend-mode: normal;
  opacity: 0.6;
  text-decoration: none;
  margin-bottom: 12px;
  letter-spacing: 0.2px;
}

.aboutContainer {
  width: 286px;
  padding-left: 17px;

  @media (max-width: $desktop) {
    width: 229px;
  }
}

.companiesContainer {
  width: 183px;

  @media (max-width: $desktop) {
    width: 152px;
  }
}

.contactsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  vertical-align: top;
  width: 470px;

  @media (max-width: $desktop) {
    align-items: inherit;
    padding-left: 33px;
    width: 302px;
  }

  @media (max-width: $tablet) {
    padding-left: 22px;
    flex-direction: row;
    justify-content: space-around;
    width: 97%;
    height: 80px;
  }

  @media (max-width: $mobile) {
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 10px;
    flex-direction: column;
    height: 114px;
    width: 100%;
  }
}

.socialContainer {
  margin: 0 0 40px 0;

  @media (max-width: $tablet) {
    width: 297px;
    margin-top: 27px;
  }

  @media (max-width: $mobile) {
    margin-bottom: 0;
    padding-left: 19px;
    width: 300px;
    margin-top: 0px;
  }
}

.socialTitle {
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  margin: 0 110px 21px 0;
  letter-spacing: 0.2px;

  @media (max-width: $tablet) {
    display: none;
  }
}

.social {
  display: flex;
  flex-direction: row;
}

.socialIcon {
  opacity: 0.25;
  margin-right: 16px;

  @media (max-width: $desktop) {
    margin-right: 19px;
  }

  @media (max-width: $tablet) {
    margin-right: 16px;
  }

  @media (max-width: $mobile) {
    margin-right: 19px;
  }
}

.feedbackContainer {
  display: flex;
  width: 272px;
  margin-right: 8px;

  @media (max-width: $tablet) {
    margin-top: 24px;
    width: 45%;
  }

  @media (max-width: $mobile) {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0;
    padding-left: 17px;
    width: 94%;
  }
}

.feedbackIcon {
  margin: 3px 12px 0 0;
  opacity: 0.25;

  @media (max-width: $mobile) {
    margin-top: 6px;
  }
}

.feedbackText {
  width: 228px;
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  letter-spacing: 0.2px;

  @media (max-width: $tablet) {
    width: 85%;
  }
}

.mail {
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #34AAF2;
  text-decoration: none;
  letter-spacing: 0.2px;
}

.containerBottom {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto 24px;
  margin-top: 0;

  .contactsContainer {
    display: none;
  }

  @media (max-width: $desktop) {
    grid-template-columns: 2fr 1.03fr;
    max-width: 1024px;
    margin-top: 27px;
  }

  @media (max-width: $tablet) {
    max-width: 768px;
    border-top: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
    margin-top: 23px;

    .contactsContainer {
      display: flex;
    }
  }

  @media (max-width: $mobile) {
    max-width: 100%;
    padding-top: 0;
    margin-top: 0;

    .contactsContainer {
      display: flex;
    }
  }
}
.agreementContainer {
  display: flex;
  align-items: center;
  @media (max-width: $tablet) {
    display: flex;
    align-items: center;
  }

  @media (max-width: $mobile) {
    flex-direction: column-reverse;
    align-items: baseline;
  }
}

.agreement {
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  width: 550px;
  letter-spacing: 0.2px;

  @media (max-width: $desktop) {
    width: 560px;
    margin-right: 49px;
    margin-left: 0px;
    padding-left: 65px;
  }

  @media (max-width: $tablet) {
    padding-left: 49px;
    width: 294px;
  }

  @media (max-width: $mobile) {
    padding-left: 18px;
    width: 94%;
    margin-right: 0;
  }
}

.privacyPolicy {
  font-family:  MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #34AAF2;
  text-decoration: none;
  letter-spacing: 0.2px;
}

.rights {
  display: flex;
  margin-left: 305px;

  @media (max-width: $desktop) {
    margin-left: 0;
  }

  @media (max-width: $mobile) {
    margin-bottom: 22px;
    padding-top: 14px;
    margin-left: 29px;
  }
}

.restriction {
  margin-right: 12px;
}

.copyright {
  margin-top: 8px;
  font-family: MegaFon Graphik LC-Light;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
}
