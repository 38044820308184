@import '../../../assets/style/varibales';

.container {
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (max-width: $desktop) {
    margin: 117px 0px 0 0px;
  }

  @media (max-width: $tablet) {
    justify-content: center;
  }

  @media (max-width: $mobile) {
    margin: 31px 8px 17px 7px;
  }
}

.slider {
  display: flex;
  transition: transform 500ms ease;
  width: 100%;
  max-width: 892px;

  @media (max-width: $tablet) {
    padding: 0;
  }
}

.slide {
  box-sizing: border-box;
  min-width: 100%;
  opacity: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  text-align: center;

  @media (max-width: $tablet) {
    min-width: 100%;
  }
}

.animateIn {
  animation: animateIn 400ms ease forwards;
}

.animateOut {
  animation: animateOut 400ms ease;
  display: none;

  @media (max-width: $mobile) {
    display: none;
  }
}

@keyframes animateIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animateOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
